import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, Formik } from "formik";
import { i18n } from "../main/i18n/i18n";
import { Modal } from "antd";
import validateHelper from "../tools/validateHelper";
import MultiSelect from "../components/form/multipleSelect";
import DatePicker from "../components/form/datePicker";
import { PaymentMethod, MonthList, PaymentType } from "../main/enums";
import { translateEnum } from "../main/enumHelper";
import { submitEditReceipt, getReceiptList } from "./managementPageActions";
import FormikInput from "../components/form/formikTextField";
import FormikAreaInput from "../components/form/formikTextArea";
import FormikDropDown from "../components/form/formikDropDown";
import { generateYears } from "../tools/helper";

const validate = (values) => {
  const errors = {};

  validateHelper.addErrorIfFailed(errors, "customerName", [
    values.customerName,
    "validate.required",
  ]);

  validateHelper.addErrorIfFailed(errors, "paymentMethod", [
    values.paymentMethod,
    "validate.required",
  ]);

  validateHelper.addErrorIfFailed(errors, "paymentMonth", [
    values.paymentMonth.length > 0,
    "validate.required",
  ]);

  validateHelper.addErrorIfFailed(errors, "paymentDate", [
    values.paymentDate,
    "validate.required",
  ]);

  if (Object.keys(values.itemList).length > 0) {
    const errorItem = {};
    Object.values(values.itemList).forEach((i) => {
      const missingData = {};
      validateHelper.addErrorIfFailed(missingData, "cost", [
        i.cost > 0,
        "validate.required",
      ]);
      validateHelper.addErrorIfFailed(missingData, "paymentType", [
        i.paymentType,
        "validate.required",
      ]);
      if (Object.keys(missingData).length > 0) {
        errorItem[i.id] = missingData;
      }
    });
    if (Object.keys(errorItem).length > 0) {
      errors.itemList = errorItem;
    }
  }

  return errors;
};

const EditReceiptModal = (props) => {
  const {
    isOpen,
    onClose,
    selectedReceipt,
    onSubmitEditReceipt,
    onGetReceipt,
  } = props;

  const isMobile = window.innerWidth <= 768;

  const insertNewItem = (formikValues, setFieldValue) => {
    const biggestId = Math.max(
      ...Object.values(formikValues).map((obj) => obj.id)
    );

    const actualBigId = biggestId === -Infinity ? 0 : biggestId;

    const newItem = {
      id: actualBigId + 1,
      paymentType: "",
      cost: 0,
      description: "",
      months: [],
      year: new Date().getFullYear(),
    };

    const sortedItems = Object.values({
      ...formikValues,
      [newItem.id]: newItem,
    }).sort((a, b) => a.id - b.id);

    setFieldValue(
      "itemList",
      Object.fromEntries(sortedItems.map((item) => [item.id, item]))
    );
  };

  function removeObjectById(obj, idToRemove, setFieldValue) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].id === idToRemove) {
        delete obj[key];
        break;
      }
    }
    setFieldValue("itemList", obj);
  }

  const arrayConverter = (array) => {
    if (array.length > 0) {
      return array.reduce((acc, item, index) => {
        acc[index + 1] = {
          id: index + 1,
          cost: item.amount,
          paymentType: item.paymentType,
          description: item.description,
          months: item.months ? item.months.split(", ") : [],
          year: item.year || "",
        };
        return acc;
      }, {});
    } else {
      return {};
    }
  };

  return (
    <Modal
      title="Edit Receipt"
      open={isOpen}
      onCancel={onClose}
      centered
      width={"90vw"}
      zIndex={999}
      maskClosable={false}
      footer={[null]}
      styles={{
        content: {
          backgroundColor: "#f1f1f1",
        },
        header: {
          backgroundColor: "#f1f1f1",
        },
      }}
    >
      <div className="container-fluid m-0">
        <div className="ps-3 pe-3">
          <Formik
            initialValues={{
              id: selectedReceipt.id || 0,
              paymentMethod: selectedReceipt.paymentMethod || "",
              paymentDate: new Date(selectedReceipt.paymentDate) || null,
              customerName: selectedReceipt.customerName || "",
              membershipNo: selectedReceipt.membershipNo || "",
              paymentMonth: selectedReceipt.paymentForMonth
                ? selectedReceipt.paymentForMonth.split(", ")
                : [],
              itemList: selectedReceipt.paymentList
                ? arrayConverter(selectedReceipt.paymentList)
                : {},
            }}
            enableReinitialize
            validateOnChange={false}
            validate={validate}
            onSubmit={(values) => {
              onSubmitEditReceipt(values);
              onGetReceipt();
              onClose();
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div>
                  <FormikInput
                    name="membershipNo"
                    id="membershipNo"
                    type="text"
                    placeholder="Enter membership no."
                    label="Membership No."
                    labelClassName="formlabel"
                    divClassName="mb-2"
                  />
                  <FormikInput
                    name="customerName"
                    id="customerName"
                    type="text"
                    placeholder="Enter customer name"
                    label="Customer Name"
                    labelClassName="formlabel"
                  />
                  <Field
                    name="paymentDate"
                    id="paymentDate"
                    placeholder="Select payment date"
                    component={DatePicker}
                    label="Payment Date"
                    divClassName="mt-3 d-flex flex-column justify-content-center align-self-center"
                  />
                  <Field
                    name="paymentMethod"
                    id="paymentMethod"
                    placeholder="Select payment method"
                    isMulti={false}
                    component={MultiSelect}
                    options={translateEnum(PaymentMethod)}
                    label="Payment Method"
                    divClassName="mt-3"
                  />
                  <Field
                    name="paymentMonth"
                    id="paymentMonth"
                    placeholder="Select payment for month"
                    isMulti={true}
                    component={MultiSelect}
                    options={translateEnum(MonthList)}
                    label="Month"
                    divClassName="mt-3"
                  />
                </div>
                <div className="mt-4 d-flex flex-row justify-content-between">
                  <div className="d-flex align-items-center justify-content-center">
                    <h3 className="page-title">Items</h3>
                  </div>
                  <div
                    className={`d-flex align-items-center justify-content-center ${
                      isMobile ? "pb-2" : ""
                    }`}
                  >
                    <button
                      type="button"
                      className="small-btn"
                      onClick={() =>
                        insertNewItem(values.itemList, setFieldValue)
                      }
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div
                  className="row overflow-y-auto overflow-x-hidden p-2 gap-3"
                  style={{
                    maxHeight: "40vh",
                  }}
                >
                  {Object.values(values.itemList).length > 0 ? (
                    Object.values(values.itemList).map((i, index) => (
                      <div
                        className="new-item-card d-flex flex-column"
                        key={index}
                      >
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="edit-card-title">{`Item ${
                              index + 1
                            }`}</span>
                          </div>
                          <div className="d-flex align-items-center justify-content-end">
                            <button
                              type="button"
                              className="no-decoration-btn"
                              onClick={() =>
                                removeObjectById(
                                  values.itemList,
                                  i.id,
                                  setFieldValue
                                )
                              }
                            >
                              <i
                                className="fa-solid fa-circle-xmark fa-lg me-2"
                                style={{ color: "#1D2023" }}
                              ></i>
                            </button>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mt-1 gap-1 gap-lg-0">
                          <FormikDropDown
                            name={`itemList[${i.id}].paymentType`}
                            id={`itemList[${i.id}].paymentType`}
                            placeholder="Select type"
                            isMulti={false}
                            options={translateEnum(PaymentType)}
                            label="Payment Type"
                            divClassName="col-12 col-lg-2"
                          />
                          <FormikInput
                            name={`itemList[${i.id}].cost`}
                            id={`itemList[${i.id}].cost`}
                            type="number"
                            placeholder="Enter cost"
                            label="Cost"
                            labelClassName="formlabel"
                            divClassName="col-12 col-lg-2"
                          />
                          <Field
                            name={`itemList[${i.id}].months`}
                            id={`itemList[${i.id}].moonths`}
                            placeholder="Select month"
                            isMulti={true}
                            component={MultiSelect}
                            options={translateEnum(MonthList)}
                            label="Month (Optional)"
                            divClassName="col-12 col-lg-4"
                          />
                          <Field
                            name={`itemList[${i.id}].year`}
                            id={`itemList[${i.id}].year`}
                            placeholder="Select year"
                            component={MultiSelect}
                            options={generateYears()}
                            label="Year"
                            divClassName="col-12 col-lg-4"
                          />
                        </div>
                        <div className="row d-flex justify-content-center p-1">
                          <FormikAreaInput
                            name={`itemList[${i.id}].description`}
                            id={`itemList[${i.id}].description`}
                            row={2}
                            col={44}
                            placeholder="Description (Optional)"
                            label="Description"
                            labelClassName="formlabel"
                            divClassName="mb-2 col-12"
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="col-12 d-flex justify-content-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <h1
                        className="align-self-center text-center"
                        style={{ color: "rgba(128,128,128,0.6)" }}
                      >
                        {i18n.t("snj.oneItemRequired")}
                      </h1>
                    </div>
                  )}
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <button
                    type="reset"
                    className="generate-btn mt-1 p-1 me-2"
                    onClick={onClose}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="generate-btn mt-1 p-1"
                    disabled={Object.values(values.itemList).length === 0}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

EditReceiptModal.propTypes = {
  count: PropTypes.number,
  state: PropTypes.object,
  isOpen: PropTypes.bool,
  selectedReceipt: PropTypes.object,

  onClose: PropTypes.func,
  onSubmitEditReceipt: PropTypes.func,
};

const mapStateToProps = (state) => ({
  count: state.home.count,
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitEditReceipt: (values) => dispatch(submitEditReceipt(values)),
  onGetReceipt: () => dispatch(getReceiptList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReceiptModal);
