/* eslint-disable import/prefer-default-export */
// import dateHelper from 'common/form/dateHelper';

export const mapReceiptPaymentListToApi = (data) =>
  data.map((datas) => ({
    description: datas.description || null,
    cost: parseFloat(datas.cost) || null,
    payment_type: datas.paymentType || null,
    months: datas.months.join(", ") || null,
    year: datas.year || null,
  }));

export const mapReceiptToApi = (data) => ({
  customer_name: data.customerName,
  membership_no: data.membershipNo,
  payment_method: data.paymentMethod,
  payment_month: data.paymentMonth.join(", "),
  payment_date: data.paymentDate,
  item_list: mapReceiptPaymentListToApi(Object.values(data.itemList)),
});
