/* eslint-disable import/prefer-default-export */
// import dateHelper from 'common/form/dateHelper';

export const mapReceiptPaymentListFromApi = (data) =>
  data.map((datas) => ({
    id: datas.id || null,
    amount: datas.amount || null,
    description: datas.description || null,
    paymentType: datas.payment_type || null,
    receiptId: datas.receipt_id || null,
    receiptNo: datas.receipt_no || null,
    months: datas.months || null,
    year: datas.year || null,
  }));

export const mapReceiptListFromApi = (data) =>
  data.map((datas) => ({
    id: datas.id || null,
    paymentDate: datas.payment_date || null,
    paymentForMonth: datas.payment_for_month || null,
    paymentList: mapReceiptPaymentListFromApi(datas.payment_list) || [],
    totalAmount: datas.total_amount || null,
    paymentMethod: datas.payment_method || null,
    receiptNo: datas.receipt_no || "",
    customerName: datas.customer_name || "",
    membershipNo: datas.membership_no || "",
  }));

export const mapReceiptPaymentListToApi = (data) =>
  data.map((datas) => ({
    description: datas.description || null,
    cost: parseFloat(datas.cost) || null,
    payment_type: datas.paymentType || null,
    months: datas.months.join(", ") || null,
    year: datas.year || null,
  }));

export const mapReceiptToApi = (data) => ({
  id: data.id,
  customer_name: data.customerName,
  membership_no: data.membershipNo,
  payment_method: data.paymentMethod,
  payment_month: data.paymentMonth.join(", "),
  payment_date: data.paymentDate,
  item_list: mapReceiptPaymentListToApi(Object.values(data.itemList)),
});
