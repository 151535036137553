import { takeEvery, put, call, select } from "redux-saga/effects";
import apiService from "../tools/apiService";
import { mapReceiptToApi } from "./homeSelector";
import {
  INIT,
  SUBMIT_RECEIPT,
  setSubmitting,
  setSubmitedId,
  setPdfViewer,
} from "./homeActions";

// eslint-disable-next-line require-yield
function* submitReceipt({ payload }) {
  yield put(setSubmitting(true));
  yield put(setPdfViewer(true));
  const { token } = yield select((state) => state.user);
  try {
    const apiValue = mapReceiptToApi(payload);
    const response = yield call(
      apiService.postWithToken,
      "/createReceipt",
      token,
      apiValue
    );
    if (response.success) {
      yield put(setSubmitedId(response.generatedId));
    }
  } catch (error) {
    // yield put(loginFailure(error.message));
  }
}

function* onPageLoad() {}

export default function* homeSaga() {
  yield takeEvery(INIT, onPageLoad);
  yield takeEvery(SUBMIT_RECEIPT, submitReceipt);
}
